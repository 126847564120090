import { loadPrebid } from "../tags/loadPrebid";
import { amazonA9 } from "../tags/amazonA9";

let googletag = {};
let dmQueryParams;
let pbjs;
let apstagPubId = "3548";
let timeOutObj = {
  apstagBid: 2000,
  failSafe: 3750, // Bidder Timeout: 2500 * 1.5
};
let getApstag;

export function initBidManager(initGoogletag, initDmQueryParams, initTimeoutObj = {}) {
  googletag = initGoogletag;
  dmQueryParams = initDmQueryParams;
  timeOutObj["failSafe"] = initTimeoutObj["failSafe"] ?? timeOutObj["failSafe"];
  timeOutObj["apstagBid"] = initTimeoutObj["apstagBid"] ?? timeOutObj["apstagBid"];
  getApstag = makeApstag();
  pbjs = makePbjs();
}

function makeApstag() {
  amazonA9();
  // window.apstagを返しても、
  // window.apstagそのものの変更が反映されなくなるので
  // get関数を返すようにして、apstagを使う時に最新の状態する
  return () => {
    return window.apstag;
  };
}

export function initApstag() {
  try {
    getApstag().init({
      pubID: apstagPubId,
      adServer: "googletag",
      bidTimeout: timeOutObj["apstagBid"],
    });
  } catch {
    console.log("An error occurred during the initialization of Apstag.");
  }
}

function makePbjs() {
  loadPrebid(dmQueryParams);
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];
  return window.pbjs;
}

export function fetchHeaderBids(apstagSlots, headerBiddingSlots, setServices) {
  const bidders = ["a9", "prebid"];

  const requestManager = {
    adserverRequestSent: false,
  };

  bidders.forEach(function (bidder) {
    requestManager[bidder] = false;
  });

  const requestBids = () => {
    if (apstagSlots && apstagSlots.length > 0) {
      getApstag().fetchBids(
        {
          slots: apstagSlots,
        },
        () => {
          headerBidderBack("a9");
        }
      );
    }

    googletag.cmd.push(() => {
      pbjs.que.push(() => {
        pbjs.rp.requestBids({
          callback: () => headerBidderBack("prebid"),
          gptSlotObjects: headerBiddingSlots,
        });
      });
    });
  };

  const headerBidderBack = (bidder) => {
    if (requestManager.adserverRequestSent) return;

    if (bidder === "a9") {
      googletag.cmd.push(() => getApstag().setDisplayBids());
    } else if (bidder === "prebid") {
      // DO NOTHING
    }

    requestManager[bidder] = true;

    if (allBiddersBack()) {
      sendAdserverRequest();
    }
  };

  const allBiddersBack = () => {
    return bidders.every((bidder) => requestManager[bidder]);
  };

  const sendAdserverRequest = () => {
    if (requestManager.adserverRequestSent) return;
    requestManager.adserverRequestSent = true;
    googletag.cmd.push(() => {
      if (setServices && typeof setServices === "function") {
        setServices("ads", headerBiddingSlots);
      } else {
        googletag.pubads().refresh(headerBiddingSlots);
      }
    });
  };

  requestBids(apstagSlots, headerBiddingSlots);

  window.setTimeout(() => {
    sendAdserverRequest();
  }, timeOutObj["failSafe"]);
}
