export const makeGeoEdgeTag = () => {
  window.grumi = {
    cfg: {
      advs: {
        57010853: true,
        4470253408: true,
        4520900043: true,
        4521150227: true,
        4526711233: true,
        4560227961: true,
        4577145831: true,
        4577730880: true,
        4811363908: true,
        4883691806: true,
        5115414692: true,
        4843813175: true,
      },
      pubIds: {},
    },
    key: "9e8ceea0-ec91-4164-adb2-0fe9a0645567",
  };
  var geoedge_element = document.createElement("script");
  geoedge_element.type = "text/javascript";
  geoedge_element.async = true;
  geoedge_element.src = "//rumcdn.geoedge.be/grumi-ip.js";
  var geoedge_target_element = document.getElementsByTagName("head")[0];
  geoedge_target_element.insertBefore(geoedge_element, geoedge_target_element.firstChild);
};
