export const amazonA9 = () => {
  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) return;

    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }

    a[a9] = {
      init: function () {
        apstag._Q.push(["i", arguments, new Date().getTime()]);
      },
      fetchBids: function () {
        apstag._Q.push(["f", arguments, new Date().getTime()]);
      },
      setDisplayBids: function () {},
      _Q: [],
      targetingKeys: function () {
        return [];
      },
      _Q: [],
    };
    A = p.createElement(s);
    A.async = !0;
    A.src = t;
    g = p.getElementsByTagName(s)[0];
    g.parentNode.insertBefore(A, g);
  })("apstag", window, document, "script", "//c.amazon-adsystem.com/aax2/apstag.js");
};
