import { userAgent } from "../constants/globalConstants";

// Detect OS
export const getOS = () => {
  var os = "";
  if (userAgent.indexOf("iphone") != -1) {
    os = "iphone";
  } else if (userAgent.indexOf("android") != -1) {
    os = "android";
  } else if (userAgent.indexOf("windows nt") != -1) {
    os = "windows";
  } else if (userAgent.indexOf("mac os x") != -1) {
    os = "mac";
  } else {
    os = "other";
  }
  return os;
};

// Detect Browser
export const getBrowser = () => {
  var browser = "";
  if (userAgent.indexOf("msie") != -1 || userAgent.indexOf("trident") != -1) {
    browser = "ie";
  } else if (userAgent.indexOf("edg") != -1) {
    browser = "edge";
  } else if (userAgent.indexOf("chrome") != -1) {
    browser = "chrome";
  } else if (userAgent.indexOf("safari") != -1) {
    browser = "safari";
  } else if (userAgent.indexOf("firefox") != -1) {
    browser = "firefox";
  } else {
    browser = "other";
  }
  return browser;
};

export const getAoneSegment = () => {
  //セグメント値取得
  var goAcn = "ASAHISEG";
  var goAco = window.document.cookie;
  var goApos = goAco.indexOf(goAcn + "=");
  var goAseg = goApos != -1 ? goAco.substring(goApos + goAcn.length + 1, goAco.indexOf("; ", goApos) != -1 ? goAco.indexOf("; ", goApos) : goAco.length) : null;
  goAseg = goAseg ? unescape(goAseg) : null;
  goAseg = goAseg ? (goAseg.substr(-1) != "/" ? goAseg + "/" : goAseg) : null;
  goAseg = goAseg ? (goAseg = goAseg.replace(/=/g, " |")) : null;
  goAseg = goAseg ? (goAseg = goAseg.replace(/\//g, "| ")) : null;
  return goAseg;
};

export const getUserSegmentIdsLocal = () => {
  var segments = [];
  if (typeof window.localStorage === "object" && typeof window.localStorage.getItem === "function" && localStorage.getItem("cxSegments") !== null && localStorage.getItem("cxSegments").length > 0) {
    segments = localStorage.getItem("cxSegments").split(",");
  }
  return segments;
};

export const getHBTimeoutObject = () => {
  var timeout_array = [3750];
  // var timeout_array_index = Math.floor( Math.random() * timeout_array.length );
  var timeout_array_index = 0;
  return {
    failSafeTimeout: timeout_array[timeout_array_index],
    prebid_wrapper_key: "failSafeTimeout" + timeout_array[timeout_array_index] + "ms",
  };
};

export const getGoogletag = () => {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];
  return window.googletag;
};
