const services = ["ads"];
const requestManager = {};
let localSlots = [];
let localGoogletag;

export const init = (googletag) => {
  localGoogletag = googletag;
};

export const setServices = (service, slots) => {
  if (service === "ads") {
    localSlots.push(slots);
  }

  requestManager[service] = true;

  if (allServicesBack()) {
    sendAdserverRequest();
  }
};

const allServicesBack = () => {
  return services.every((service) => requestManager[service]);
};

const sendAdserverRequest = () => {
  localGoogletag.cmd.push(() => {
    for (let i = 0; i < localSlots.length; i++) {
      localGoogletag.pubads().refresh(localSlots[i]);
    }
    localSlots = [];
  });
};
